import { create } from "@incodetech/welcome";
import { environment } from "@utils/constants";

const incodeConfig = {
  apiURL: environment.incodeApiUrl ?? "",
  apiKey: environment.incodeApiKey ?? "",
};

const incode = create(incodeConfig);

export default incode;

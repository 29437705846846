import { useEffect, useRef } from "react";

import { StepContainer } from "./IdentityVerification.styles";
import incode from "incode";

interface SelfieProps {
  session: any;
  onSuccess: (res?: any) => void;
  onError: (e?: any) => void;
}

function Selfie({ session: token, onSuccess, onError }: SelfieProps) {
  const containerRef = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    incode.renderCamera("selfie", containerRef.current, {
      onSuccess,
      onError,
      token,
      numberOfTries: 3,
      showTutorial: true,
      showCustomCameraPermissionScreen: true,
      showDoublePermissionsRequest: true,
    });
    isMounted.current = true;
  }, [onSuccess, onError, token]);

  return <StepContainer ref={containerRef} />;
}

export default Selfie;

import { useEffect, useRef } from "react";

import { StepContainer } from "./IdentityVerification.styles";
import incode from "incode";

interface BackIdProps {
  session: any;
  onSuccess: () => void;
  onError: (e?: any) => void;
}

function BackId({ session: token, onSuccess, onError }: BackIdProps) {
  const containerRef = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    incode.renderCamera("back", containerRef.current, {
      onSuccess,
      onError,
      token,
      numberOfTries: -1,
      showTutorial: true,
      showCustomCameraPermissionScreen: true,
      showDoublePermissionsRequest: true,
    });

    isMounted.current = true;
  }, [onSuccess, onError, token]);

  return <StepContainer className="incode_back-id" ref={containerRef} />;
}

export default BackId;

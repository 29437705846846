import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import BackId from "./BackId";
import ComponentLoader from "@component/Loading/ComponentLoader";
import FaceMatch from "./FaceMatch";
import FrontId from "./FrontId";
import FullScreenState from "@component/FullScreenState/FullScreenState";
import OnboardingComplete from "./OnboardingComplete";
import OnboardingError from "./OnboardingError";
import ProcessId from "./ProcessId";
import ResetPermissions from "./ResetPermissions";
import RetrySteps from "./RetrySteps";
import Selfie from "./Selfie";
import { environment } from "@utils/constants";
import incode from "incode";
import { usePermissions } from "@hooks/usePermissions";

export enum VerificationStatus {
  VERIFIED = "VERIFIED",
  VERIFYING = "VERIFYING",
  VERIFIED_INVALID = "VERIFIED_INVALID",
}

function Steps() {
  const [step, setStep] = useState(0);
  const [session, setSession] = useState<any>();
  const [resetPermissions, setResetPermissions] = useState(false);
  const [liveness, setLiveness] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [isSecondId, setIsSecondId] = useState(false);
  const [error, setError] = useState(false);
  const permissionsState = usePermissions();

  const StepContainer = ({
    children,
    currentStep,
  }: {
    children: React.ReactElement[];
    currentStep: number;
  }) => (
    <>
      {Children.map(
        children,
        (child, index) => currentStep === index && cloneElement(child)
      )}
    </>
  );

  useEffect(() => {
    const configurationId = environment.incodeFlowId;

    incode
      .createSession("ALL", undefined, {
        configurationId,
      })
      .then(async (session) => {
        await incode.warmup();
        setSession(session);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // if permissions are denied from start, let's show the reset permissions screen
    setResetPermissions(permissionsState === "denied");
  }, [permissionsState]);

  const handleNextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const handleError = useCallback((event?: any) => {
    if (event?.type === "permissionDenied") {
      setResetPermissions(true);
      return;
    }

    setError(true);
  }, []);

  if (!session) {
    return <ComponentLoader />;
  }

  if (resetPermissions) {
    return <ResetPermissions />;
  }

  if (error) return <OnboardingError />;

  return (
    <FullScreenState backgroundColor="#fff">
      <StepContainer currentStep={step}>
        <FrontId
          session={session}
          onSuccess={handleNextStep}
          onError={handleError}
        />
        <BackId
          session={session}
          onSuccess={handleNextStep}
          onError={handleError}
        />
        <ProcessId session={session} onSuccess={handleNextStep} />
        <Selfie
          session={session}
          onSuccess={(res) => {
            setLiveness(res?.liveness);
            setUserExists(res?.existingUser);
            setIsSecondId(res?.isSecondId);
            handleNextStep();
          }}
          onError={handleError}
        />
        <FaceMatch
          session={session}
          onSuccess={handleNextStep}
          onError={handleError}
          liveness={liveness}
          existingUser={userExists}
          isSecondId={isSecondId}
        />
        <RetrySteps
          session={session}
          onSuccess={handleNextStep}
          onError={handleError}
          numberOfTries={3}
        />
        <OnboardingComplete session={session} />
      </StepContainer>
    </FullScreenState>
  );
}

export default Steps;
